import { useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import RequestFeatureModal from "./RequestFeatureModal";
const RequestFeature = ({
  username,
  className = "dropdown-item",
  study_id,
  patient_name,
  patient_id,
  accesor,
  study_type,
  study_date,
  StudyInstanceUID,
}) => {
  const [show, setShow] = useState(false);
 
  const toggle = () => {
    setShow(prev=>!prev);
  };

  return (
    <>
    {show?<RequestFeatureModal
      username={username}
      study_id={study_id}
      patient_name={patient_name}
      patient_id={patient_id}
      accesor={accesor}
      study_type={study_type}
      study_date={study_date}
      StudyInstanceUID={StudyInstanceUID}
      setShow={setShow}
    />:null}
    <div style={{position:'static'}} className="d-flex flex-column ">
      <button onClick={toggle} className={className} type="button">
        Request Feature
      </button>
    </div>
    </>
  );
};

export default RequestFeature;
